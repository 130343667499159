<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="报告名称：" prop="name"><el-input v-model="formData.name" :disabled="dialogObj.type == 0"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="报告类型：" prop="type">
							<el-radio-group v-model="formData.type" >
								<el-radio :label="1">月度体检报告</el-radio>
								<el-radio :label="2">设备状态分析报告</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="选择日期：" prop="time">
							<el-date-picker v-model="formData.time" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="dialogObj.type == 0"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="所属设备：" prop="unitId">
							<el-cascader
								ref="unit"
								v-model="formData.unitId"
								:options="unitList"
								:props="{ expandTrigger: 'hover', value: 'id' }"
								@change="handleChange"
							></el-cascader>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注："><el-input type="textarea" v-model="formData.remarks" :disabled="dialogObj.type == 0"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="文档附件：" prop="file">
							<el-upload
								class="avatar-uploader"
								action=""
								:before-upload="BeforeUpload"
								:on-remove="handleRemove"
								:http-request="Upload"
								:file-list="fileList"
								accept=".pdf"
								:disabled="dialogObj.type == 0"
							>
								<el-button size="small" type="primary" :disabled="dialogObj.type == 0">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过10Mb</div>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()" v-if="dialogObj.type != 0">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import {
	dialogMixin
} from "@m/dialogMixin"
import configApi from '@config/configApi'
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: "",
			loading: false,
			formData: {
				unitId: ""
			},
			newFile: new FormData(),
			fileList: [],
			unitList: [],
			rules: {
				name: [{
					required: true,
					message: '请填写报告名称',
					trigger: 'change'
				}],
				time: [{
					required: true,
					message: '请选择日期',
					trigger: 'change'
				}],
				type: [{
					required: true,
					message: '请选择报告类型',
					trigger: 'change'
				}],
				unitId: [{
					required: true,
					message: '请选择所属设备',
					trigger: 'change'
				}],
			}
		};
	},
	methods: {
		//获取单体列表
		getunitList() {
			this.$get("/backend-api/web/monitor/tree").then((res) => {
				if (res.code == 1000) {
					this.unitList = res.data[0].children;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		handleChange(value) {
			let node = this.$refs.unit.getCheckedNodes();
			this.formData.unitId = value[value.length-1];
			this.formData.unitName = node[0].label;
		},
		//打开弹窗
		open() {
			this.fileList = [];
			this.formData = {
				unitId: ""
			};
			this.newFile = new FormData();
			this.get();
			this.getunitList();
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			})
		},
		//上传前校验
		BeforeUpload(file) {
			if (this.fileList.length > 0) {
				this.$message.error('只能上传一份');
				return false;
			}
			const isLt2M = file.size / 1024 / 1024 < 10;
			var filtype = file.name.substring(file.name.lastIndexOf(".") + 1);
			const extension = filtype === "pdf";
			if (!extension) {
				this.$message.error('上传文件只能是 PDF 格式!');
			} else if (!isLt2M) {
				this.$message.error('上传大小不能超过 10MB!');
			} else {
				if (file) {
					if(this.newFile.has("file")){
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		handleRemove(file, fileList) {
			this.formData.url = "";
			this.fileList = fileList;
		},
		//手动上传
		Upload() {
			this.loading = true;
			this.$uploadWithName(this.newFile).then((res) => {
				if (res.code == 1000) {
					this.formData.url = res.data
					this.loading = false;
				} else {
					this.loading = false;
					this.$message.error(res.msg);
				}
			}).catch(error => {
				this.$message.error(error);
			});
		},
		//获取数据
		get() {
			if (this.dialogObj.type == 0 || this.dialogObj.type == 1) {
				if (this.dialogObj.type == 0) {
					this.title = "查看";
				} else {
					this.title = "编辑";
				}
				this.$get("/backend-api/eqp/report/get", {
					id: this.dialogObj.id
				}).then((res) => {
					if (res.code == 1000) {
						if(!res.data) return
						this.formData = res.data
						if (this.formData.url) {
							let filePath = this.formData.url;
							let index = filePath.lastIndexOf("/");
							this.fileList.push({
								name: filePath.substring(index + 1, filePath.length)
							});
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			} else if (this.dialogObj.type == 2) {
				this.title = "上传报告";
			}
		},
		//提交表单
		submit() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (!this.formData.url || this.formData.url == "") {
						this.$message.error("请上传附件");
						return;
					}
					this.loading = true;
					this.$postData("/backend-api/eqp/report/save", this.formData).then((res) => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					});
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}
</style>
